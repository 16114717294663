// What we do

import React, {useRef, useState, useEffect} from 'react'
import CenterIndicator from "./CenterIndicator"
import ChevronLeft from './svg-icons/chevronLeft'
import ChevronRight from './svg-icons/chevronRight'
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

export default function Testimonials() {

    SwiperCore.use([Autoplay])

    const containerRef = useRef(null)
    const goToPage = (page) => window.open(page, '_blank')

    return (
        <>
            <div id="testimonials" className="container mt-20 mb-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20 relative">
                <section className="grid w-100 justify-items-center">
                    <CenterIndicator num="05"/>

                    <div className="flex flex-row items-center">
                        <button aria-label="Previous testimonial" className={`prev text-servo-500 mr-6 mt-2`} onClick={e => containerRef.current.swiper.slidePrev()}><ChevronLeft /></button>
                        <h2 data-aos="fade-in" className="text-5xl font-bold">Testimonials</h2>
                        <button aria-label="Next testimonial" className={`prev text-servo-500 ml-6 mt-2`} onClick={e => containerRef.current.swiper.slideNext()}><ChevronRight /></button>
                    </div>
                </section>
            </div>

            <Swiper 
                spaceBetween={40}
                slidesPerView={1}
                autoplay={{
                    delay: 3500,
                    waitForTransition: false
                }}
                className="px-[14vw] flex w-full pb-10"
                ref={containerRef}
            >

                <SwiperSlide onClick={() => goToPage('https://revologycars.com/')} data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-out-quart" className="cursor-pointer min-h-[460px] w-full relative overflow-hidden border border-stone-200 rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300">
                    <img loading="lazy" alt="Revology logo" src={process.env.PUBLIC_URL + "/images/webp/revology-bg.webp"} className="absolute inset-0 w-full h-full object-cover object-bottom" />
                    <div className="absolute inset-0 h-full w-full bg-gradient-to-br from-black/75"></div>
                    
                    <div className="relative min-h-[460px] p-12 flex flex-col justify-between items-start">
                        <img loading="lazy" alt="Revology" className="h-[60px] mb-8" src={process.env.PUBLIC_URL + "/images/webp/revology-logo.webp"} />
                        <p className="text-white mb-6 w-full max-w-full md:w-3/4">“In a 30+ year career both as an entrepreneur and with Fortune 500 companies, I have never worked with a more competent and capable IT services provider than Martin Jirkal.  Martin is extremely knowledgeable not just about IT, but also about business, so he is able to provide added value in almost every aspect of our operation.  Based on my experience with Martin over the past five years, I can confidently recommend Martin and Servodata.”</p>
                        
                        <div>
                            <h3 className="text-lg font-bold text-white">Tom Scarpello</h3>
                            <h4 className="text-sm text-white">Revology Cars LLC, Founder & CEO <br /><small>Client since 2016 (Full service IT department)</small></h4>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide onClick={() => goToPage('https://www.apavsolutions.com/')} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-out-quart" data-aos-delay="400" className="cursor-pointer min-h-[460px] w-full relative overflow-hidden border border-stone-200 rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300">
                    <img loading="lazy" src={process.env.PUBLIC_URL + "/images/webp/apav-bg.webp"} alt="APAV Logo" className="absolute inset-0 w-full h-full object-cover object-bottom" />
                    <div className="absolute inset-0 h-full w-full bg-gradient-to-br from-black/75"></div>
                    
                    <div className="relative min-h-[460px] p-12 flex flex-col justify-between items-start">
                        <img loading="lazy" alt="APAV Solutions" className="h-[60px] mb-8" src={process.env.PUBLIC_URL + "/images/webp/apav-logo.webp"} />
                        <p className="text-white mb-6 w-full max-w-full md:w-3/4">“Servodata has earned our respect and trust through the way they handle IT design, deployment and after sales care.  APAV Solutions looks forward to a long and successful IT relationship with Servodata.”</p>
                        
                        <div>
                            <h3 className="text-lg font-bold text-white">Craig Beyrooti</h3>
                            <h4 className="text-sm text-white">APAV Solutions, GM & Owner <br /><small>Client since 2017 (Full service IT department)</small></h4>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide onClick={() => goToPage('https://www.teammarketgroup.com/')} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-out-quart" data-aos-delay="400" className="cursor-pointer min-h-[460px] w-full relative overflow-hidden border border-stone-200 rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300">
                    <img loading="lazy" src={process.env.PUBLIC_URL + "/images/webp/tmg-bg.webp"} alt="TMG Logo" className="absolute inset-0 w-full h-full object-cover object-bottom" />
                    <div className="absolute inset-0 h-full w-full bg-gradient-to-br from-black/75"></div>
                    
                    <div className="relative min-h-[460px] p-12 flex flex-col justify-between items-start">
                        <img loading="lazy" alt="TMG Group" className="max-h-[80px] mb-8" src={process.env.PUBLIC_URL + "/images/webp/tmg-logos.webp"} />
                        <p className="text-white mb-6 w-full max-w-full md:w-3/4">“Always a pleasurable experience dealing with Servodata. His expertise and professionalism is second to none as is his friendly attitude and availability. Fast and efficient he always gets the job done. Look no further for any of your IT needs.”</p>
                        
                        <div>
                            <h3 className="text-lg font-bold text-white">Keith Mawardi</h3>
                            <h4 className="text-sm text-white">Team Market Group, GM & Owner<br /><small>Client since 2018 (Full service IT department, buildouts, IT PM and deployment)</small></h4>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide onClick={() => goToPage('https://yogademocracy.com/')} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-out-quart" data-aos-delay="400" className="cursor-pointer min-h-[460px] w-full relative overflow-hidden border border-stone-200 rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300">
                    <img loading="lazy" src={process.env.PUBLIC_URL + "/images/webp/yoga-bg.webp"} alt="Yoga Democracy Logo" className="absolute inset-0 w-full h-full object-cover object-bottom" />
                    <div className="absolute inset-0 h-full w-full bg-gradient-to-br from-black/75"></div>
                    
                    <div className="relative min-h-[460px] p-12 flex flex-col justify-between items-start">
                        <img loading="lazy" alt="Yoga Democracy" className="max-h-[65px] mb-8 invert" src={process.env.PUBLIC_URL + "/images/webp/yoga-logo.webp"} />
                        <p className="text-white mb-6 w-full max-w-full md:w-3/4">“We have worked with Martin Jirkal, the owner of Servodata for more than five years. Throughout this time, he has exhibited upmost professionalism, hard-work, and ingenuity. Martin and his team has handled all of our IT needs throughout this five-year period at all of our lactations in both Orlando and out of state.  To assist our team, Martin has traveled to Puerto Rico, New York City and Phoenix. He is conscientious and always responds in a timely manner.  We would highly recommend Servodata for any of your IT needs.”</p>
                        
                        <div>
                            <h3 className="text-lg font-bold text-white">Holly Cannon</h3>
                            <h4 className="text-sm text-white">YogaDemocracy, Co-Owner<br /><small>Client since 2015 (Full service IT department)</small></h4>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide onClick={() => goToPage('https://www.egis-group.com/')} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-out-quart" data-aos-delay="400" className="cursor-pointer min-h-[460px] w-full relative overflow-hidden border border-stone-200 rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300">
                    <img loading="lazy" src={process.env.PUBLIC_URL + "/images/webp/egis-bg.webp"} alt="Egisprojects Logo" className="absolute inset-0 w-full h-full object-cover object-bottom" />
                    <div className="absolute inset-0 h-full w-full bg-gradient-to-br from-black/75"></div>
                    
                    <div className="relative min-h-[460px] p-12 flex flex-col justify-between items-start">
                        <img loading="lazy" alt="Egisprojects" className="max-h-[60px] mb-8" src={process.env.PUBLIC_URL + "/images/webp/egis-logo.webp"} />
                        <p className="text-white mb-6 w-full max-w-full md:w-3/4">“Martin Jirkal and Servodata have worked as an external IT Partner of Egis since 2015. I say partner because they are an integral part of Egis’ operations.  They ensure that all IT components are maintained and updated as needed. We could call on Martins team anytime to assist with technical issues with various employees.  Martin is thorough in his assessment of the IT issues and does a great job communicating the problems and how to resolve them. I would recommend Servodata be included in your operations as an IT Partner.“</p>
                        
                        <div>
                            <h3 className="text-lg font-bold text-white">Madeline Washington</h3>
                            <h4 className="text-sm text-white">Egis Projects USA, Project Director<br /><small>Client since 2015 (White label support in cooperation with parent IT department in France)</small></h4>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide onClick={() => goToPage('https://www.lafamiliapawn.com/')} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-out-quart" data-aos-delay="400" className="cursor-pointer min-h-[460px] w-full relative overflow-hidden border border-stone-200 rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300">
                    <img loading="lazy" src={process.env.PUBLIC_URL + "/images/webp/lafamilia-bg.webp"} alt="La Familia Pawn Logo" className="absolute inset-0 w-full h-full object-cover object-bottom" />
                    <div className="absolute inset-0 h-full w-full bg-gradient-to-br from-black/75"></div>
                    
                    <div className="relative min-h-[460px] p-12 flex flex-col justify-between items-start">
                        <img loading="lazy" alt="La Familia Pawn" className="max-h-[65px] mb-8" src={process.env.PUBLIC_URL + "/images/webp/lafamilia-logo.webp"} />
                        <p className="text-white mb-6 w-full max-w-full md:w-3/4">Testimonial coming soon.</p>
                        
                        <div>
                            <h3 className="text-lg font-bold text-white">Jack Hegner</h3>
                            <h4 className="text-sm text-white">La Familia Pawn & Jewelry, CTO<br /><small>Client since 2018 (IT consulting, Senior Server support, New stores IT buildouts)</small></h4>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    )
}