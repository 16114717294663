export default function Surveillance(){
    return (
        <svg className="w-20 m-auto stroke-servo-400 fill-transparent stroke-2" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
            <g xmlns="http://www.w3.org/2000/svg" id="web-cam">
                <circle className="st0" cx="24" cy="28.7" r="17"/>
                <circle cx="24" cy="28.7" r="7"/>
                <circle className="st1" cx="26.2" cy="27.1" r="2.1"/>
                <g>
                    <path className="st0" d="M7.9,3.3l32.1,0c0,0,3-0.2,3.6,1.1l0,0c0.6,1.2-0.1,2.9-1.6,3.5c-11.5,4.9-24.6,4.9-36.1,0    C4.4,7.2,3.7,5.6,4.3,4.3l0,0C5,3.1,7.9,3.3,7.9,3.3z"/>
                </g>
            </g>
        </svg>
    );
}