// Career block

import CareerForm from "./careerForm";
import VerticalIndicator from "./VerticalIndicator";

export default function Career() {
    return (
        <>
            <div id="career" className="container mt-20 mb-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20">
                <section className="grid w-100 justify-items-end">
                    <h2 data-aos="fade-in" className="z-10 text-5xl font-bold">Career</h2>
                    <VerticalIndicator num="06" align="right" />
                </section>
                <section className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 relative">
                    <div className="lg:col-start-1 order-2 lg:order-1">
                        <CareerForm />
                    </div>
                    <div className="lg:col-start-2 order-1 lg:order-2">
                        <p data-aos="fade-in" data-aos-delay="0" data-aos-offset="-50" className="py-3">The new digital age has completely changed the role of technology in our lives and hand in hand with that the necessary skills of an IT professional. We believe that as much as knowledge and experience is a key success, the technology jobs require additional personal features, namely passion and dedication, with a sprinkle of geekiness.</p>
                        
                        <p data-aos="fade-in" data-aos-delay="50" data-aos-offset="-50" className="mt-3 font-bold">What does it mean?</p>
                        <p data-aos="fade-in" data-aos-delay="100" data-aos-offset="-50" className="py-3">We are always looking for a great talent to extend our team.</p>
                        
                        <p data-aos="fade-in" data-aos-delay="150" data-aos-offset="-50" className="mt-3 font-bold">And what do you need to be the right candidate?</p>
                        <p data-aos="fade-in" data-aos-delay="200" data-aos-offset="-50" className="py-3">The passion and dedication to do the right thing. To stick with the problem, until it's resolved. To never give up on your task, on the client, on your team.</p>
                        <p data-aos="fade-in" data-aos-delay="300" data-aos-offset="-50" className="py-3">If you are a dynamic thinker, that doesn’t seek a 9to5 comfort chair and wanna be a part of something new and cool in an amazing time of building a new team in young organization; if you read what we do and it makes you feel like you’re a match; and if you can sign in all CAPS under our mission statement, then we are already waiting for you!</p>
                        <p data-aos="fade-in" data-aos-delay="400" data-aos-offset="-50" className="py-3">And yes, we expect that you know your way around computers… 🙂</p>

                        <p data-aos="fade-in" data-aos-delay="400" data-aos-offset="-50" className="py-3">Please send us your resume to <a href="mailto:careers@servodata.us" className="text-servo-600">careers@servodata.us</a>.</p>
                    </div>
                </section>
            </div>
        </>
    )
}