// Hero

import { HashLink } from "react-router-hash-link"
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

export default function HeroSlider() {

    // Offset to scroll @todo move away
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    SwiperCore.use([EffectFade])

    return (
        <>
        <div className="z-0 w-full h-screen absolute left-0 top-0 bg-logicboard overflow-hidden">
            <div className="bg-gradient-to-t from-white w-screen h-screen absolute bottom-0 left-0"></div>
        </div>

        <div data-aos="fade-up" data-delay="180" data-duration="400" className="herocard container mt-16 mx-auto flex items-center flex-row place-content-between px-6 sm:px-0 lg:px-20">
            <section className="min-h-96 flex justify-center items-center flex-1 flex-shrink-0 bg-gray-100 overflow-hidden shadow-lg rounded-lg relative py-16 md:py-20 xl:py-48">
                <img src={process.env.PUBLIC_URL + "/images/webp/hero1.webp"} alt="Servodata" className="animate-zoom w-full h-full object-cover object-center absolute inset-0" />
                <div className="bg-servo-200 mix-blend-multiply absolute inset-0"></div>

                <div className="sm:max-w-xl flex flex-col items-center relative p-4">
                    <span className="text-white hidden md:block w-full text-lg sm:text-xl text-center mb-4 md:mb-8 min-h-[60px]">
                        <Swiper
                            fadeEffect={{ crossFade: true }}
                            effect="fade"
                            rewind="true"
                            noSwiping={true}
                            initialSlide={0}
                            slidesPerView={1}
                            autoplay={{
                                delay: 2000,
                                waitForTransition: true
                            }}
                        >
                            <SwiperSlide>
                            Full scope IT department scaled for <br />each clients individual needs.
                            </SwiperSlide>
                            <SwiperSlide>
                            Your IT Solution - Expertise proven by time.<br />Re-established in 2021.
                            </SwiperSlide>
                            <SwiperSlide>
                            Full integration with existing IT departments <br />and infrastructure.
                            </SwiperSlide>
                        </Swiper>
                    </span>
                    <h2 className="text-white text-3xl md:text-4xl sm:text-5xl lg:text-6xl font-bold text-center mb-8 md:mb-12">
                        One stop shop IT services
                    </h2>

                    <div className="flex flex-col sm:flex-row sm:justify-center gap-2.5">
                        <HashLink scroll={scrollWithOffset} smooth to="/#about-us" className={`inline-block text-white bg-servo-gradient hover:bg-servo-600 active:bg-servo-700 focus-visible:ring ring-servo-300 text-sm md:text-base font-semibold text-center rounded-tr-lg rounded-bl-lg outline-none transition duration-100 px-8 py-3`}>
                            Find out more
                        </HashLink>

                        <HashLink smooth to="/#contact-us" className={`inline-block text-black bg-white hover:bg-gray-200 active:bg-gray-200 focus-visible:ring ring-servo-300 text-sm md:text-base font-semibold text-center rounded-tr-lg rounded-bl-lg outline-none transition duration-100 px-8 py-3`}>
                            Contact us
                        </HashLink>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}