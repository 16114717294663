// Skill box container

import CenterIndicator from "./CenterIndicator"

export default function Skillbox({icon, name, text, className, delay, background = "", small = false}) {
    return (
        <div data-aos="fade-in-up" data-aos-delay={delay} data-aos-offset="100" style={{"backgroundImage": "url("+background+")"}} className={`${className} bg-cover place-content-center ${small ? "" : "min-h-[260px]"} bg-center cursor-pointer relative group bg-no-repeat hover:bg-[center_bottom_30rem] z-10 flex flex-col items-center border border-stone-200 bg-white rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300 transition-all duration-500 ease-in-out px-4 py-1 lg:px-8 lg:py-4`}>
            <div className="group-hover:opacity-0 opacity-100 transition-opacity ease-in-out absolute w-full text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h3 className="text-xl font-bold mb-3 text-white">{name}</h3>
                {icon}
            </div>
            <div className="group-hover:opacity-100 opacity-0 group-hover:delay-300 transition-opacity ease-in-out text-center relative block">
                <h3 className={`${small ? "text-xl" : "text-lg"} font-bold mb-3`}>{name}</h3>
                <CenterIndicator service/>
                <p className={`${small ? "" : "text-sm"}`}>{text}</p>
            </div>
        </div>
    )
}