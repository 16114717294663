// Vertical block indicator

export default function VerticalIndicator({num, align}) {
    return (
        (
            align==='right' ? 
            <div data-aos="fade-in" className="select-none z-10 grid grid-cols-1 divide-x divide-servo-600 my-4 -mr-5">
                <div></div>
                <div className="pl-2 h-28 text-servo-600">{num}</div>
            </div> : 
            <div data-aos="fade-in" className="select-none z-10 grid grid-cols-2 divide-x divide-servo-600 my-4 -ml-5">
                <div className="pr-2 h-28 text-servo-600">{num}</div>
                <div></div>
            </div>
        )
    )
}