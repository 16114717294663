export default function Backups(){
    return (
        <svg className="w-20 m-auto stroke-servo-400 fill-transparent stroke-2" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
            <g xmlns="http://www.w3.org/2000/svg" id="storager-refresh">
                <ellipse fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" cx="24" cy="7.194458" rx="17" ry="5.1944447"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M41,15.1944447   c0,2.8688126-7.6111603,5.1944447-17,5.1944447S7,18.0632572,7,15.1944447"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M24,28.3888893   c-9.3888407,0-17-2.3256321-17-5.1944447"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M41,23.1944447"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M24,36.3888893   c-9.3888407,0-17-2.325634-17-5.1944447"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M24,44.3888893   c-9.3888407,0-17-2.325634-17-5.1944466"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M41,23.1944447"/>

                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="7" y1="7" x2="7" y2="40"/>

                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="41" y1="7" x2="41" y2="23"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M31.2625084,30.9996738   c1.4087448-1.9470387,3.6998177-3.2137051,6.2869148-3.2137051c4.2830276,0,7.7547188,3.4716759,7.7547188,7.754715   c0,4.2830162-3.4716911,7.7547188-7.7547188,7.7547188c-3.4255867,0-6.3321686-2.2208061-7.3586464-5.3012924"/>
                <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points="28,41 30,37    34,38  "/>
            </g>
        </svg>
    );
}