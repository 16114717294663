// Navigation

import { HashLink } from 'react-router-hash-link';
import Logo from "./Logo";

import React, {useState} from 'react'
import Hamburger from './svg-icons/hamburger';
import { Transition } from "@headlessui/react";

export default function Nav() {

    const [navbarOpen, setNavbarOpen] = useState(false);

    // Offset to scroll
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <>
        <nav className="sticky drop-shadow-sm bg-white py-4 z-40 top-0 w-screen mx-auto backdrop-filter backdrop-blur-lg">
            <div className="container mx-auto flex items-center flex-row place-content-between px-6 sm:px-0 lg:px-20">

                <div className="flex items-center lg:flex lg:items-center">
                    <div className="flex items-center justify-between">
                        <div>
                            <Logo />
                        </div>
                    </div>
                </div>

                <div className="w-full relative flex justify-end xl:hidden">
                    <button className="text-black cursor-pointer leading-none border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
                        <Hamburger />
                    </button>
                </div>

                <div className="hidden xl:flex capitalize font-semibold text-gray-600 md:px-10 mx-0 md:mx-2 lg:-mx-4 flex-row lg:flex-row lg:items-center items-center">
                    <HashLink scroll={scrollWithOffset} smooth to="/#top" className={`nav-link pt-4 pb-5 lg:mt-0 mr-2 md:mr-4 lg:mr-4 hover:text-servo-400 border-t-2 border-transparent transition duration-300 ease-in-out`}>
                        Home
                    </HashLink>
                    <HashLink scroll={scrollWithOffset} smooth to="/#about-us" className={`nav-link pt-4 pb-5 lg:mt-0 mx-2 md:mx-4 lg:mx-6 hover:text-servo-400 focus:text-servo-400 border-t-2 border-transparent transition duration-300 ease-in-out`}>
                        About us
                    </HashLink>
                    <HashLink scroll={scrollWithOffset} smooth to="/#what-we-do" className={`nav-link pt-4 pb-5 lg:mt-0 mx-2 md:mx-4 lg:mx-6 hover:text-servo-400 border-t-2 border-transparent transition duration-300 ease-in-out`}>
                        What we do
                    </HashLink>
                    <HashLink scroll={scrollWithOffset} smooth to="/#testimonials" className={`nav-link pt-4 pb-5 lg:mt-0 mx-2 md:mx-4 lg:mx-6 hover:text-servo-400 border-t-2 border-transparent transition duration-300 ease-in-out`}>
                        Testimonials
                    </HashLink>
                    <HashLink scroll={scrollWithOffset} smooth to="/#career" className={`nav-link pt-4 pb-5 lg:mt-0 mx-2 md:mx-4 lg:mx-6 hover:text-servo-400 border-t-2 border-transparent transition duration-300 ease-in-out`}>
                        Career
                    </HashLink>
                    <HashLink scroll={scrollWithOffset} smooth to="/#contact-us" className={`nav-link pt-4 pb-5 lg:mt-0 ml-2 md:ml-4 lg:ml-6 hover:text-servo-400 border-t-2 border-transparent transition duration-300 ease-in-out`}>
                        Contact us
                    </HashLink>
                </div>

            </div>
        </nav>

        <Transition
            show={navbarOpen}
            as="div"
            className="bg-servo-600 xl:hidden fixed z-30 text-center w-screen left-0 top-0 pt-[92px]"
            enter="transition-all ease-in duration-300"
            enterFrom="h-[0px] opacity-0"
            enterTo="h-auto opacity-100"
            leave="transition-all ease-out duration-300"
            leaveFrom="h-auto opacity-100"
            leaveTo="h-[0px] opacity-0"
        >
            <div className="px-2 py-2 space-y-1 sm:px-3">
                <HashLink onClick={() => setNavbarOpen(!navbarOpen)} scroll={scrollWithOffset} smooth to="/#top" className={`hover:bg-servo-700 text-neutral-100 block px-3 py-4 rounded-md text-base font-medium`}>
                    Home
                </HashLink>
                <HashLink onClick={() => setNavbarOpen(!navbarOpen)} scroll={scrollWithOffset} smooth to="/#about-us" className={`hover:bg-servo-700 text-neutral-100 block px-3 py-4 rounded-md text-base font-medium`}>
                    About us
                </HashLink>
                <HashLink onClick={() => setNavbarOpen(!navbarOpen)} scroll={scrollWithOffset} smooth to="/#what-we-do" className={`hover:bg-servo-700 text-neutral-100 block px-3 py-4 rounded-md text-base font-medium`}>
                    What we do
                </HashLink>
                <HashLink onClick={() => setNavbarOpen(!navbarOpen)} scroll={scrollWithOffset} smooth to="/#testimonials" className={`hover:bg-servo-700 text-neutral-100 block px-3 py-4 rounded-md text-base font-medium`}>
                    Testimonials
                </HashLink>
                <HashLink onClick={() => setNavbarOpen(!navbarOpen)} scroll={scrollWithOffset} smooth to="/#career" className={`hover:bg-servo-700 text-neutral-100 block px-3 py-4 rounded-md text-base font-medium`}>
                    Career
                </HashLink>
                <HashLink onClick={() => setNavbarOpen(!navbarOpen)} scroll={scrollWithOffset} smooth to="/#contact-us" className={`hover:bg-servo-700 text-neutral-100 block px-3 py-4 rounded-md text-base font-medium`}>
                    Contact us
                </HashLink>
            </div>
        </Transition>
        </>
    );
}