import React from "react";
import { Route, Routes } from 'react-router-dom';

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { history } from "./scripts/history";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} exact />
      <Route path='*' element={<NotFound history={history} />} />
    </Routes>
  );
}

export default App;
