export default function ProjectManagement(){
    return (
        <svg className="w-20 m-auto stroke-servo-400" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
            <g xmlns="http://www.w3.org/2000/svg" id="list">
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="   M41,45H7c-2.2,0-4-1.8-4-4V7c0-2.2,1.8-4,4-4h34c2.2,0,4,1.8,4,4v34C45,43.2,43.2,45,41,45z"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="3" y1="9" x2="45" y2="9"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="8" y1="6" x2="8" y2="6"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="11" y1="6" x2="11" y2="6"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="14" y1="6" x2="14" y2="6"/>
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="10.5" cy="17.5" r="2.5"/>
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="10.5" cy="26.5" r="2.5"/>
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="10.5" cy="35.5" r="2.5"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="18" y1="18" x2="39" y2="18"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="18" y1="27" x2="39" y2="27"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="18" y1="36" x2="39" y2="36"/>
            </g>
        </svg>
    );
}