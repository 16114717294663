export default function Networks(){
    return (
        <svg className="w-20 m-auto stroke-servo-400 fill-transparent stroke-2" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
            <g xmlns="http://www.w3.org/2000/svg" id="channel">
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M19.1715736,16.8284264   c-1.5620975-1.5620966-1.5620975-4.0947561,0-5.6568537"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M28.8284264,11.1715727   c1.5620975,1.5620975,1.5620975,4.0947571,0,5.6568537"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M15.9080029,20.0919971   c-3.3645163-3.3645172-3.3645163-8.8194771,0-12.1839943"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M32.0919952,7.9080033   c3.3645172,3.3645167,3.3645172,8.8194771,0,12.1839943"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M12.8076115,23.1923885   c-5.0768156-5.0768166-5.0768156-13.3079605,0-18.3847771"/>
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M35.1923866,4.8076119   c5.0768166,5.0768161,5.0768166,13.3079605,0,18.3847771"/>
                
                    <polygon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="   24,15 22,46 27,46  "/>
            </g>
        </svg>
    );
}