export default function Infrastructure(){
    return (
        <svg className="w-20 m-auto fill-servo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.05 155.05">
            <g>
                <g>
                    <path className="cls-1" d="M152,3a10.34,10.34,0,0,0-7.32-3H10.34A10.35,10.35,0,0,0,0,10.34V144.7a10.35,10.35,0,0,0,10.34,10.35H144.7a10.35,10.35,0,0,0,10.35-10.35V10.34A10.36,10.36,0,0,0,152,3ZM7.27,7.27A4.31,4.31,0,0,1,10.34,6H144.7a4.35,4.35,0,0,1,4.35,4.34V27.1H6V10.34A4.31,4.31,0,0,1,7.27,7.27Zm140.5,140.5a4.26,4.26,0,0,1-3.07,1.28H10.34A4.35,4.35,0,0,1,6,144.7V33.1H149.05V144.7A4.26,4.26,0,0,1,147.77,147.77Z" />
                    <path className="cls-1" d="M16.55,21.24a4.68,4.68,0,1,0-3.32-1.37A4.71,4.71,0,0,0,16.55,21.24Zm0-4.69h0l.92-.92Zm0,0h0l-.92.92.92-.92-.92-.92Z" />
                    <path className="cls-1" d="M30.1,21.24a4.68,4.68,0,1,0-3.32-1.37A4.71,4.71,0,0,0,30.1,21.24Zm0-4.69h0l-.92.92Zm.92-.92-.92.92v0Z" />
                    <path className="cls-1" d="M43.65,21.24a4.68,4.68,0,1,0-3.32-1.37A4.71,4.71,0,0,0,43.65,21.24Zm0-4.69h0l.92.92Zm0,0h0v0Zm0,0h0l-.92.92.92-.92-.92-.92Z" />
                    <path className="cls-1" d="M77.52,47.42a43.65,43.65,0,1,0,30.87,12.79A43.51,43.51,0,0,0,77.52,47.42Zm26.63,70.28a37.67,37.67,0,1,1,11-26.63A37.56,37.56,0,0,1,104.15,117.7Z" />
                    <path className="cls-1" d="M92.34,75.4l-25,25-8-8a3,3,0,0,0-4.24,4.24h0l10.16,10.16a3,3,0,0,0,4.24,0l27.1-27.1a3,3,0,0,0-4.24-4.24Z" />
                </g>
            </g>
        </svg>
    );
}