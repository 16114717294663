// src/pages/NotFound.js

import { Link } from "react-router-dom";

export default function NotFound (props) {
    const {history} = props;

    return (
        <>
        <main>
            <section className="text-gray-900 flex items-center font-code">
                <div className="container flex flex-col items-center justify-center h-screen mx-auto relative px-6 sm:px-0 lg:px-20">
                    <h1 className="font-display font-bold text-4xl md:text-6xl">Not found</h1>
                    <p className="mt-4 text-center">
                        If you think this is a mistake, please contact us.<br /> Otherwise return back to the homepage.
                    </p>

                    <Link to='/' className="bg-servo-600 text-white py-2 px-4 rounded-md shadow-md mt-8">Go back to Servodata</Link>
                </div>
            </section>
        </main>
        </>
    );
}