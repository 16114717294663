// About us block

import ContactForm from "./contactForm";
import Mail from "./svg-icons/mail";
import Map from "./svg-icons/map";
import Phone from "./svg-icons/phone";
import VerticalIndicator from "./VerticalIndicator";
import Linkedin from "./svg-icons/linkedin";
import Facebook from "./svg-icons/facebook";
import Review from "./svg-icons/review";

export default function Contact() {
    return (
        <>
            <div id="contact-us" className="container mt-20 mb-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20 relative">
                <section className="grid w-100 justify-items-start">
                    <h2 data-aos="fade-in" className="z-10 text-5xl font-bold">How do you contact us?</h2>
                    <VerticalIndicator num="07" align="left" />
                </section>
                <section className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 relative z-20">
                    <div className="lg:col-span-1 flex flex-col md:flex-row lg:flex-col justify-center lg:justify-start mb-10 lg:mb-0 gap-3">
                        <div data-aos="fade-up" data-aos-delay="0" className="flex flex-row align-center items-center font-medium text-neutral-900">
                            <Phone classes="stroke-servo-500 sm:mr-4 md:mr-2 md:ml-4 lg:ml-0 lg:mr-4" /> <a href="tel:+1(689)500-2145">+1(689)500-2145</a>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="100" className="flex flex-row align-center items-center font-medium text-neutral-900">
                            <Mail classes="stroke-servo-500 sm:mr-4 md:mr-2 md:ml-4 lg:ml-0 lg:mr-4" /> <a href="mailto:hello@servodata.us">hello@servodata.us</a>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="200" className="flex flex-row align-center items-center font-medium text-neutral-900">
                            <Map classes="stroke-servo-500 sm:mr-4 md:mr-2 md:ml-4 lg:ml-0 lg:mr-4" /> 6645 Vineland Rd, Suite 210<br/>Orlando, FL 32819<br/>United States of America
                        </div>
                        <br />
                        <div data-aos="fade-up" data-aos-delay="200" className="flex flex-row align-center items-center font-medium text-neutral-900">
                            <Facebook classes="fill-servo-600 sm:mr-4 md:mr-2 md:ml-4 lg:ml-0 lg:mr-4" /> <a href="https://www.facebook.com/ServodataUS/" className="text-servo-800 hover:text-servo-600 transition-all ease-in-out" target="_blank" rel="noreferrer">ServodataUS</a>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="200" className="flex flex-row align-center items-center font-medium text-neutral-900">
                            <Linkedin classes="fill-servo-600 sm:mr-4 md:mr-2 md:ml-4 lg:ml-0 lg:mr-4" /> <a href="https://www.linkedin.com/company/servodata-us/" className="text-servo-800 hover:text-servo-600 transition-all ease-in-out" target="_blank" rel="noreferrer">Servodata LLC</a>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="200" className="flex flex-row align-center items-center font-medium text-neutral-900">
                            <Review classes="fill-servo-600 sm:mr-4 md:mr-2 md:ml-4 lg:ml-0 lg:mr-4" /> <a href="https://search.google.com/local/writereview?placeid=ChIJAf70fQB_54gRPlMhb7SJLRA" className="text-servo-800 hover:text-servo-600 transition-all ease-in-out" target="_blank" rel="noreferrer">Leave a review!</a>
                        </div>
                    </div>
                    <div className="lg:col-span-3 lg:col-start-2 relative">
                        <ContactForm />
                    </div>
                </section>
            </div>

            <img data-aos="fade-down" data-aos-duration="450" data-aos-easing="ease-in-out-quad" data-aos-offset="600" className="select-none absolute left-0 z-10 bottom-28 max-w-full" alt="background graphics" src={process.env.PUBLIC_URL + "/images/webp/reversebg.webp"}></img>
        </>
    )
}