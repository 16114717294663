export default function Servers(){
    return (
        <svg className="w-20 m-auto stroke-servo-400 fill-transparent stroke-2" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
            <g xmlns="http://www.w3.org/2000/svg" id="drive-shelve">
                <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="   M42.9999924,42H5.0000062c-1.1000001,0-2.0000002-0.9000015-2.0000002-2v-8c0-1.1000004,0.9000001-2,2.0000002-2h37.9999847   c1.1000023,0,2,0.8999996,2,2v8C44.9999924,41.0999985,44.0999947,42,42.9999924,42z"/>
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="36" cy="36" r="2"/>
                    <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="   M42.9999924,30H5.0000062c-1.1000001,0-2.0000002-0.8999996-2.0000002-2v-8c0-1.1000004,0.9000001-2,2.0000002-2h37.9999847   c1.1000023,0,2,0.8999996,2,2v8C44.9999924,29.1000004,44.0999947,30,42.9999924,30z"/>
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="36" cy="24" r="2"/>
                    <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="   M42.9999924,18H5.0000062c-1.1000001,0-2.0000002-0.8999996-2.0000002-2V8c0-1.0999999,0.9000001-2,2.0000002-2h37.9999847   c1.1000023,0,2,0.9000001,2,2v8C44.9999924,17.1000004,44.0999947,18,42.9999924,18z"/>
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="36" cy="12" r="2"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="9" y1="11" x2="9" y2="13"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="12" y1="11" x2="12" y2="13"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="11" x2="15" y2="13"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="9" y1="23" x2="9" y2="25"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="12" y1="23" x2="12" y2="25"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="23" x2="15" y2="25"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="9" y1="35" x2="9" y2="37"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="12" y1="35" x2="12" y2="37"/>
                    <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="35" x2="15" y2="37"/>
            </g>
        </svg>
    );
}