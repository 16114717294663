// Contact form

import Button from "./forms/Button";
import Input from "./forms/Input";
import Textarea from "./forms/Textarea";

import axios from 'axios';
import React, { useState } from "react";
import toast from 'react-hot-toast';
import reactGoogleRecaptcha3 from "react-google-recaptcha3";

export default function ContactForm() {

    const [formLoading, setFormLoading] = useState(false)
    const [formState, setFormState] = useState({
        email: '',
        phone: '',
        message: '',
    })

    const handleChange = (evt) => setFormState({...formState, [evt.target.name]: evt.target.value})
    const handleSubmit = (e) => {
        e.preventDefault()
        setFormLoading(true)

        const formData = new FormData()
        for (const [key, value] of Object.entries(formState)) {
            formData.append(key, value)
        }

        if (!formState.email || !formState.phone) {
            toast.error('Your submission is missing required fields!')
            setFormLoading(false)
            return
        }

        reactGoogleRecaptcha3.getToken().then(token => {
            formData.append('token', token)

            axios
            .post('/api/contact-form', formData)
            .then((res) => {
                toast('Form succesfuly submitted!')
                setFormLoading(false)
            })
            .catch((err) => {
                setFormLoading(false)
                toast.error(err.response.data.error)}
            )
        }, error => {
            setFormLoading(false)
            toast.error('Unable to load captcha for your connection!')
        })
    }

    return (
        <div data-aos="fade-up" data-aos-delay="100" data-aos-offset="-50"  className={`z-10 relative flex flex-col items-start border border-stone-200 bg-white rounded-lg shadow-lg shadow-neutral-200 p-4 lg:p-8`}>
            <div className="w-full flex flex-row items-center mb-4">
                <h3 className="text-2xl font-bold lg:w-1/3">Send us a message</h3><hr className="bg-servo-600 h-[1.5px] w-full hidden lg:block shadow-none drop-shadow-none" />
            </div>
        
            <div className="w-full flex flex-col md:flex-row md:gap-4">
                <div className="mt-4 w-full md:w-1/2">
                    <Input onChange={handleChange} name="phone" label="Phone number" required={true} placeholder="Your phone number" type="text" />
                </div>
                <div className="mt-4 w-full md:w-1/2">
                    <Input onChange={handleChange} name="email" label="Your e-mail" required={true} placeholder="Your e-mail address" type="text" />
                </div>
            </div>

            <div className="mt-4 w-full">
                <Textarea onChange={handleChange} name="message" label="Your message" placeholder="Leave us a message!" />
            </div>

            <div className="mt-8 w-full text-right">
                <Button disabled={formLoading} onClick={handleSubmit} text="Contact us" />
            </div>
        </div>
    )
}