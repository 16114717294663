export default function SoftwareSupport(){
    return (
        <svg className="w-20 m-auto stroke-servo-400 fill-transparent stroke-2" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48">
            <g xmlns="http://www.w3.org/2000/svg" id="load">
                <path fill="none" strokeWidth="2" strokeMiterlimit="10" d="M44,46H4c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h40   c1.1,0,2,0.9,2,2v40C46,45.1,45.1,46,44,46z"/>
                <circle fill="none" strokeWidth="2" strokeMiterlimit="10" cx="6" cy="6" r="0.5"/>
                <circle fill="none" strokeWidth="2" strokeMiterlimit="10" cx="10" cy="6" r="0.5"/>
                <circle fill="none" strokeWidth="2" strokeMiterlimit="10" cx="14" cy="6" r="0.5"/>
                <line fill="none" strokeWidth="2" strokeMiterlimit="10" x1="2" y1="10" x2="46" y2="10"/>
                
                    <circle fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="24" cy="28" r="12"/>
                
                    <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="   18,29 21,32 29,24  "/>
            </g>
        </svg>
    );
}