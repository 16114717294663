// What we do
import CenterIndicator from './CenterIndicator';
import VerticalIndicator from './VerticalIndicator';
import Skillbox from './Skillbox';

// SVG Icons
import Backups from './svg-icons/backups';
import CloudSolutions from './svg-icons/cloudSolutions';
import Networks from './svg-icons/networks';
import ProjectManagement from './svg-icons/projectManagement';
import Servers from './svg-icons/servers';
import SoftwareSupport from './svg-icons/softwareSupport';
import Surveillance from './svg-icons/surveillance';
import Buildouts from './svg-icons/buildouts';
import Infrastructure from './svg-icons/infrastructure';
import Button from './forms/Button';

export default function WhatWeDo() {

    const goToCorpus = () => window.open('https://corpus.cx/', '_blank')

    return (
        <>
            <div id="what-we-do" className="container mt-20 mb-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20">
                <section className="grid w-100 justify-items-center mb-12">
                    <CenterIndicator num="03" />
                    <h2 data-aos="fade-in" className="text-5xl font-bold">What we do</h2>
                </section>

                <section className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                    <Skillbox delay="0" icon={<CloudSolutions />} background={process.env.PUBLIC_URL + "/images/webp/services/Cloud.webp"} name="Cloud Solutions" text="From Microsoft 365 and G-Suite to AWS and private cloud, the future of IT is here and we are ready to push your IT to the 21st century." />
                    <Skillbox delay="50" icon={<ProjectManagement />} background={process.env.PUBLIC_URL + "/images/webp/services/IT-Project.webp"} name="IT Project Management" text="Whether for a new deployment project, upgrade project or a move/migration, we have the know-how and experience to ensure the projects are finished properly and on time. The long term experience approves of a statement, that every project, big or small, needs a manager to ensure its success." />

                    <Skillbox delay="150" icon={<Networks />} background={process.env.PUBLIC_URL + "/images/webp/services/Networks.webp"} name="Networks, Telco & Internet" text="Buildout, maintenance and monitoring of wired and wireless networks, with focus on security of your communications and information. ISP & VoIP vendor management and monitoring, making sure you are connected and available. Our network and phone services include building and managing on-premises SIP phone systems." />
                    <Skillbox delay="200" icon={<SoftwareSupport />} background={process.env.PUBLIC_URL + "/images/webp/services/Helpdesk.webp"} name="Helpdesk & Software Support" text="Ongoing support with 360 monitoring covering all areas of users IT needs with an optimal mix of remote and on-site presence.We do not claim to know every software out there, no one can. What we do know, is how to quickly adopt to new systems and work with vendor support teams to ensure you’re smooth sailing." />
                    <Skillbox delay="250" icon={<Backups />} background={process.env.PUBLIC_URL + "/images/webp/services/Backups.webp"} name="Business Continuity" text="On-premises and cloud based backup and replication solutions are a key components to business continuity planning, together with Disaster Recovery plans and backup hardware readiness. We apply a comprehensive analysis of your IT infrastructure to build tailored custom solutions and work with the most major brand backup and replication suites, specializing in Nakivo and Veeam products." />

                    <Skillbox delay="300" icon={<Servers />} background={process.env.PUBLIC_URL + "/images/webp/services/Servers.webp"} name="Servers" text="Specializing in Dell and HP server lineups, Physical and Virtual, On-premises and Datacenter deployments, Microsoft Hyper-V, VMware and Citrix. New deployments, upgrades and migrations to the cloud, we got you covered." />
                    <Skillbox delay="350" icon={<Surveillance />} background={process.env.PUBLIC_URL + "/images/webp/services/IP-surveillance.webp"} name="IP Surveillance Systems" text="Digital IP based surveillance system brings a wide range of possibilities in scalability and advanced monitoring features. We specialize in making surveillance a cost-effective integral part of our buildout projects." />
                    <Skillbox delay="350" icon={<Buildouts />} background={process.env.PUBLIC_URL + "/images/webp/services/Buildouts.webp"} name="Buildouts" text="Commercial and hospitality IT buildouts are our forte, from planning through to the final delivery. With a help of our local area partners, we cover all aspects of a properly developed and build project." />
                    <Skillbox delay="350" icon={<Infrastructure />} background={process.env.PUBLIC_URL + "/images/webp/services/IT-Infrastructure.webp"} name="IT Infrastructure Assessments" text="Not sure what the condition of you IT infrastructure is? How old is it, what can you expect of it in terms of life expectancy? What direction to take with the upgrade? We have the answers!" />
                </section>

                <section className="grid w-100 justify-items-start mt-24">
                    <h2 data-aos="fade-in" className="text-5xl font-bold">Partners</h2>
                    <VerticalIndicator num="04" />
                </section>

                <section className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 relative">
                    <div className="lg:col-start-1 order-2 lg:order-1">
                        <p data-aos="fade-in" data-aos-delay="0" data-aos-offset="-50" className="py-3 font-bold">When we say that we are a ONE STOP SHOP for all your technological needs, we really mean it.</p>
                        <p data-aos="fade-in" data-aos-delay="100" data-aos-offset="-50" className="py-3">We still may be a small business, but through the years we have developed strong partnerships with local top businesses, whose specializations cover for the areas we do not specialize ourselves for.</p>
                        <p data-aos="fade-in" data-aos-delay="200" data-aos-offset="-50" className="py-3">Through our partners we cover three major areas: Graphic and web design, Wiring and Low-voltage deployments and Audio/Video Solutions. Depending on your requirements, we the handshake and introduction so you can work directly with the partner, or we white label their services into one complete package under Servodata brand.</p>
                    </div>
                    <div className="lg:col-start-2 order-1 lg:order-2">
                        <img loading="lazy" alt="Partners graphic" className="w-5/6 m-auto md:-mt-40 mb-4" src={process.env.PUBLIC_URL + "/images/webp/Partners.webp"} />
                    </div>
                </section>

                <section className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 relative mt-20">
                    <div className="lg:col-start-1 order-2 lg:order-1 h-full">
                        <div data-aos="fade-in-up" data-aos-delay="350" data-aos-offset="100" style={{"backgroundImage": "url("+process.env.PUBLIC_URL + "/images/webp/corpusBg.webp"+")"}} className={`h-full bg-cover relative bg-no-repeat z-10 items-center border border-stone-200 bg-white rounded-lg shadow-lg hover:shadow-xl shadow-stone-100 hover:shadow-stone-300 transition-all duration-500 ease-in-out p-4 lg:p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3`}>
                            <div className="text-center col-span-2 relative">
                                <img loading="lazy" alt="Corpus Megaphone" className="max-w-[50%] md:max-w-[50%] lg:max-w-[100%] absolute top-1/2 left-1/2 transform -translate-x-[0%] md:-translate-x-[10%] lg:-translate-x-[60%] -translate-y-[40%] md:-translate-y-[30%] lg:-translate-y-[60%]" src={process.env.PUBLIC_URL + "/images/webp/megafon.webp"} />
                            </div>
                            <div className="text-left relative block col-span-1 py-10">
                                <h3 className="text-3xl text-[#49abdd] font-bold mb-3">Corpus.cx</h3>
                                <h3 className="text-3xl text-white font-bold mb-3">Marketing &<br />Advertising Agency</h3>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-start-2 order-1 lg:order-2 py-6">
                        <img loading="lazy" alt="Corpus Logo" className="mb-4" src={process.env.PUBLIC_URL + "/images/webp/corpusLogo.webp"} />
                        <p data-aos="fade-in" data-aos-delay="0" data-aos-offset="-50" className="py-3 font-bold">Corpus is part of our history since. 1993 as part of family business.</p>
                        <p data-aos="fade-in" data-aos-delay="100" data-aos-offset="-50" className="py-3">Corpus have everything you need to launch and grow your business. Develop brands and their campaigns in conjunction with print and digital ads (desktop and mobile), pushing our work into new innovation areas to help our clients reach the right audience, revolutionizing traditional media with unique formats, interactive features and innovative technologies that politely demand user attention.</p>
                        <Button onClick={goToCorpus} text="Website" classes={"mt-4"} />
                    </div>
                </section>

                <section className="w-[80%] m-auto mt-16 mb-16">
                    <CenterIndicator />
                </section>

                <section className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8">
                    <Skillbox small delay="0" background={process.env.PUBLIC_URL + "/images/webp/services/Audio.webp"} name="Wiring and Low-voltage deployments" text="Our local partner is one of the Central Florida's leading electrical and wiring providers. Fully licensed and insured, the team will take care of every aspect of the job from permitting to the final handoff." />
                    <Skillbox small delay="50" background={process.env.PUBLIC_URL + "/images/webp/services/Wiring.webp"} name="Audio and Video Solutions" text="Innovative Audiovisual Professionals will help design and deploy the right audio and video solution for your space, whether it’s a small coffee shop or a large theatre." />
                </section>
            </div>
        </>
    )
}