export default function Facebook({classes}){
    return (
            <svg
            className={`h-10 w-10 ${classes}`}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
                />
            </svg>
    );
}