// Contact form

import Button from "./forms/Button";
import FileUpload from "./forms/FileUpload";
import Input from "./forms/Input";
import RadioGroup from "./forms/RadioGroup";
import Select from "./forms/Select";
import Textarea from "./forms/Textarea";

import axios from 'axios';
import React, { useState } from "react";
import toast from 'react-hot-toast';
import ReactRecaptcha3 from 'react-google-recaptcha3';

export default function CareerForm() {

    const [formLoading, setFormLoading] = useState(false)
    const [formState, setFormState] = useState({
        email: '',
        phone: '',
        name: '', 
        years: '',
        age: '',
        references: '',
        applyFor: 'Support specialist',
        fulltime: false,
        cancall: false,
        callpreference: false,
        resume: null,
    })

    const handleChange = (evt) => setFormState({...formState, [evt.target.name]: evt.target.value})
    const handleFileInput = (file) => setFormState({...formState, 'resume': file})
    const handleSubmit = (e) => {
        e.preventDefault()
        setFormLoading(true)

        const formData = new FormData()
        for (const [key, value] of Object.entries(formState)) {
            formData.append(key, value)
        }

        if (!formState.email || !formState.phone || !formState.name || !formState.applyFor || !formState.cancall || !formState.fulltime) {
            toast.error('Your submission is missing required fields!')
            setFormLoading(false)
            return
        }

        ReactRecaptcha3.getToken().then(token => {
            formData.append('token', token)

            axios
            .post('/api/career-form', formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
            .then((res) => {
                setFormLoading(false)
                toast('Form succesfuly submitted!')
            })
            .catch((err) => {
                setFormLoading(false)
                toast.error(err.response.data.error)}
            )
        }, error => {
            setFormLoading(false)
            toast.error('Unable to load captcha for your connection!')
        })
    }

    return (
        <div data-aos="fade-up" data-aos-delay="100" data-aos-offset="-50" className={`z-20 relative flex flex-col items-center border border-stone-200 bg-white rounded-lg shadow-lg shadow-stone-100 p-4 lg:p-8`}>
            
            <div className="flex flex-grow-0 w-full items-center mb-4">
                <hr className="bg-servo-600 hidden lg:block h-[1.5px] w-2/3 shadow-none drop-shadow-none" />
                <h3 className="text-2xl font-bold w-full text-center">Apply for a position</h3>
                <hr className="bg-servo-600 hidden lg:block h-[1.5px] w-2/3 shadow-none drop-shadow-none" />
            </div>
            
            <div className="mt-4 w-full">
                <Input name="name" onChange={handleChange} label="Your name" required={true} placeholder="Your full name" type="text" />
            </div>

            <div className="w-full flex flex-col md:flex-row md:gap-4">
                <div className="mt-4 w-full md:w-1/2">
                    <Input name="phone" onChange={handleChange} label="Phone number" required={true} placeholder="Your phone number" type="text" />
                </div>
                <div className="mt-4 w-full md:w-1/2">
                    <Input name="email" onChange={handleChange} label="Your e-mail" required={true} placeholder="Your e-mail address" type="text" />
                </div>
            </div>

            <div className="w-full flex flex-row gap-4">
                <div className="mt-4 w-1/2">
                    <Input name="years" onChange={handleChange} label="Years of experience" placeholder="Years of experience" type="number" />
                </div>
                <div className="mt-4 w-1/2">
                    <Input name="age" onChange={handleChange} label="Your age" placeholder="Your age" type="number" />
                </div>
            </div>

            <div className="mt-4 w-full">
                <Textarea name="references" onChange={handleChange} label="Your references" placeholder="Companies you worked with.." />
            </div>

            <div className="mt-4 w-full">
                <Select name="applyFor" onChange={handleChange} label="Apply for" required={true} id="position" options={[
                    {
                        name: 'Support specialist',
                        value: 'support-specialist'
                    }
                ]} />
            </div>

            <div className="w-full flex flex-col md:flex-row md:gap-4 md:divide-x md:divide-servo-600">
                <div className="mt-4 w-full md:w-1/3 -mr-4">
                    <RadioGroup onChange={handleChange} label="Full-time employment?" required={true} id="fulltime" name="fulltime" options={[
                        {
                            name: 'yes',
                            label: 'Yes'
                        },
                        {
                            name: 'No',
                            label: 'No'
                        }
                    ]} />
                </div>
                <div className="mt-4 w-full md:w-1/3 pl-4 -ml-4">
                    <RadioGroup onChange={handleChange} label="Can we call you?" required={true} id="cancall" name="cancall" options={[
                        {
                            name: 'yes',
                            label: 'Yes'
                        },
                        {
                            name: 'No',
                            label: 'No'
                        }
                    ]} />
                </div>
                <div className="mt-4 w-full md:w-1/3 pl-4 -ml-4">
                    <RadioGroup onChange={handleChange} label="Call preference" id="callpreference" name="callpreference" options={[
                        {
                            name: 'morning',
                            label: 'Morning'
                        },
                        {
                            name: 'afternoon',
                            label: 'Afternoon'
                        }
                    ]} />
                </div>
            </div>

            <div className="mt-4 w-full">
                <FileUpload name="resume" onChange={handleFileInput} label="Your resume" id="cv" />
            </div>

            <div className="mt-8 w-full text-right">
                <Button disabled={formLoading} onClick={handleSubmit} text="Submit application" />
            </div>

        </div>
    )
}