export default function RadioGroup ({id, label, name, required, onChange, classes, options}) {
    return (
    <div className="relative flex flex-col">
        <label htmlFor={name} className="w-full mb-2">{label} {required ? <span className="text-red-600">*</span> : ''}</label>

        <div className="flex">
            {options.map(opt => 
                <div key={opt.name} className="flex items-center mb-2 mr-4">
                    <input onChange={onChange} value={opt.label} type="radio" id={id+'_'+opt.name} name={name} className={`h-4 w-4 text-gray-700 px-3 py-3 border rounded mr-2 ${classes}`}/>
                    <label htmlFor={id+'_'+opt.name} className="text-gray-600">{opt.label}</label>
                </div>
            )}
        </div>
    </div>
    );
}