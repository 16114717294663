
import React, {useEffect} from 'react';
import AboutUs from '../components/AboutUs';
import Career from '../components/Career';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import HeroSlider from '../components/HeroSlider';
import Nav from '../components/Nav';
import Testimonials from '../components/Testimonials';
import WhatWeDo from '../components/WhatWeDo';

import AOS from 'aos';
import 'aos/dist/aos.css';
import reactGoogleRecaptcha3 from 'react-google-recaptcha3';
import { Toaster } from 'react-hot-toast';

export default function Home () {
    useEffect(() => {
        AOS.init({
            once: true,
            mirror: false,
            easing: 'ease-in-out'
        })

        reactGoogleRecaptcha3.init('6LdLH4IfAAAAAJE3Ge8CGHTkCTjY1WC9wb2HAEGV')
    })

    return (
        <main className="relative">
            <Nav />
            <HeroSlider />
            <AboutUs />
            <WhatWeDo />
            <Testimonials />
            <Career />
            <Contact />
            <Footer />
            
            <Toaster position="bottom-center" />
        </main>
    )
}