// About us block

import VerticalIndicator from "./VerticalIndicator";

export default function AboutUs() {
    return (
        <>
            <div id="about-us" className="container mt-20 mb-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20">
                <section className="grid w-100 justify-items-end">
                    <h2 data-aos="fade-in" className="z-10 text-5xl font-bold">About us</h2>
                    <VerticalIndicator num="01" align="right" />
                </section>
                <section className="grid grid-cols-1 md:grid-cols-2 relative">
                    <div className="md:col-start-1">
                        <img alt="IT Specialist graphic" data-aos="fade-up-right" data-aos-duration="450" data-aos-easing="ease-in-out-quad" className="select-none relative md:absolute max-w-[40%] -top-40 hidden md:block" src={process.env.PUBLIC_URL + "/images/webp/aboutUs.webp"}></img>
                    </div>
                    <div className="md:col-start-2">
                        <p data-aos="fade-in" data-aos-delay="0" data-aos-offset="-100" className="py-3">The Servodata company was established in the Prague, Czech Republic in 1991 by two brothers - Jiri and Rostislav Jirkal. During its early years the company grew in size and prominence to eventually operate across the entire EMEA region, providing a full spectrum of IT services and products. From its inception, it was a true family business expanding over 3 generations.</p>
                        <p data-aos="fade-in" data-aos-delay="100" data-aos-offset="-100" className="py-3">Martin Jirkal, son of Jiri Jirkal, joined the team in 1999 and progressed through several job positions to ultimately becoming an IT Infrastructure manager, a role that covered all in-house IT operations as well as external outsourced client IT operations.</p>
                        <p data-aos="fade-in" data-aos-delay="200" data-aos-offset="-100" className="py-3">Following his definitive move to the United States in 2014, Martin set to commence a US based operation.  He spent the next 7 years establishing a home for his family, working with some of the top local businesses in the region to obtain key experiences and establish new client relations.  In the beginning of 2021, on the 30th anniversary of the original company, Martin's business started operating under the Servodata brand.</p>
                        <p data-aos="fade-in" data-aos-delay="300" data-aos-offset="-100" className="py-3">With over twenty years of IT and management experience, Martin started the next chapter of the traditional family business story, setting on a new path to bring more people to believe that in their organizations, IT can be a stress-free ally, not an ever-expensive financial drain.</p>
                    </div>
                </section>
            </div>

            <div className="container mt-20 mb-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20">
                <section className="grid w-100 justify-items-start">
                    <h2 data-aos="fade-in" className="text-5xl font-bold">Vision & mission statement</h2>
                    <VerticalIndicator num="02" align="left" />
                </section>

                <img alt="background gradient" data-aos="fade-up" data-aos-duration="450" data-aos-easing="ease-in-out-quad" data-aos-offset="600" className="select-none absolute mt-40 z-0 sm:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl right-0 hidden md:block" src={process.env.PUBLIC_URL + "/images/webp/bg_grad.webp"}></img>
                <section className="grid grid-cols-1 md:grid-cols-2 relative">
                    <div className="md:col-start-1">
                        <p data-aos="fade-in" data-aos-delay="0" data-aos-offset="-100" className="py-3">“When people talk about IT and their IT departments, I want them to have a smile on their face.  IT offices should be a place filled with good and friendly people that you want to come to visit with comfort and ease.  So, when you encounter a break or are in a need of assistance, you know whom to call, and most importantly, you feel comfortable knowing the solution is there for you.”  Martin Jirkal in his own words.</p>
                        <p data-aos="fade-in" data-aos-delay="100" data-aos-offset="-100" className="py-3">Servodata offers three decades of experience in the international IT environment, always striving for trust and excellence deeply anchored in the values of a family-operated business. We cherish and nurture our clients with a complete and unconditional understanding of the importance of a secure and smooth IT operation with a reliable IT infrastructure.</p>
                    </div>
                    <div className="md:col-start-2">
                        <img alt="Martin Jirkal" data-aos="fade-up-left" data-aos-duration="450" data-aos-easing="ease-in-out-quad" className="select-none relative block w-4/5 m-auto -mt-20 hidden md:block" src={process.env.PUBLIC_URL + "/images/webp/Statement.webp"}></img>
                    </div>
                </section>
            </div>
        </>
    )
}