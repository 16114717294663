// Footer block

import { HashLink } from "react-router-hash-link"

export default function Footer() {
    return (
        <>
            <div className="z-0 w-screen h-screen absolute left-0 bottom-0 bg-logicboard opacity-40">
                <div className="bg-gradient-to-b from-white w-screen h-screen absolute bottom-0 left-0"></div>
            </div>

            <div className="bg-white/50 filter backdrop-blur-lg z-10 relative h-14 mt-20 mx-auto place-content-between px-6 sm:px-0 lg:px-20">
                <div className="container mx-auto px-6 sm:px-0 lg:px-20 pt-4">
                    <section className="flex flex-row relative justify-between content-center">
                        <div className="">
                            <strong>© 2020-2022 Servodata</strong> | Created by <a className="text-indigo-600 font-bold" rel="noreferrer" href="https://jdckl.dev/" target="_blank">JDCKL</a>
                            </div>
                        <div className="text-gray-500">
                            <HashLink smooth to="/#top" className={`hover:text-servo-400 transition duration-300 ease-in-out flex flex-row`}>
                                Back to top
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2 pt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" /> </svg>
                            </HashLink>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}