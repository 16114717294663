export default function Select ({id, label, name, onChange, darkMode, classes, required, options}) {
    return (
    <div className="relative flex flex-col">
        <label htmlFor={id} className="w-full mb-2">{label} {required ? <span className="text-red-600">*</span> : ''}</label>
        <select placeholder="What" id={id} name={name} onChange={onChange} className={`rounded-lg border-transparent flex-1 appearance-none border ${darkMode ? '' : 'border-neutral-300'} w-full py-2 px-4 ${darkMode ? 'input-dark' : 'bg-white'} placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-servo-400 focus:border-transparent ` + classes}>
            <option disabled defaultValue className="text-gray-400">--</option>
            { options.map(opt => 
                <option key={opt.value} value={opt.value}>{opt.name}</option>
                ) }
        </select>
    </div>
    );
}